import {
  HiOutlineCog,
  HiOutlineInformationCircle,
  HiOutlineLogout,
} from "react-icons/hi";
import { Typography } from "@earned/core";
import { ClientRoutes } from "@common/constants";
import Sidebar from "@common/components/Sidebar";
import SidebarLink from "@common/components/Sidebar/SidebarLink";
import useClientNav from "../Sidebar/useClientNav";
import useStore from "@common/state";
import { useLogout } from "@common/hooks";

const ClientSidebar = () => {
  const { isSidebarExpanded } = useStore();
  const sidenav = useClientNav();
  const { setShowLogoutDialog } = useLogout();

  return (
    <Sidebar showAdvisorAccess={true}>
      <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden scrollbar-none">
        {sidenav.map((section, index) => {
          return (
            <div key={index}>
              <div className={`sidebar-section-header px-10`}>
                <span className={section.containerClassName}>
                  <Typography variant="body-xs">
                    {section.sectionTitle}
                  </Typography>
                </span>
              </div>
              {section.links.map((link, index) => {
                return (
                  <SidebarLink
                    key={index}
                    href={link.href}
                    icon={link.icon}
                    text={link.text}
                    isLocked={link.isLocked}
                    badgeCount={link.badgeCount}
                    confirmMessage={link.confirmMessage}
                  />
                );
              })}
            </div>
          );
        })}

        <div className={"sidebar-section-header px-10 "}>
          <div
            className={`whitespace-nowrap ${
              isSidebarExpanded
                ? "sidebar-section-text-expanded"
                : "sidebar-section-text-collapsed"
            }`}
          >
            Settings
          </div>
        </div>
        <SidebarLink
          href={ClientRoutes.Disclosures}
          icon={<HiOutlineInformationCircle />}
          text="Disclosures"
        />
        <SidebarLink
          href={ClientRoutes.Settings}
          icon={<HiOutlineCog />}
          text="Profile and Privacy"
        />
        <SidebarLink
          text="Log Out"
          icon={<HiOutlineLogout />}
          href=""
          onClickLink={() => setShowLogoutDialog(true)}
        />
        <div className="mb-9 mt-9"></div>
      </div>
    </Sidebar>
  );
};
export default ClientSidebar;
